import React, { useState } from "react";
import "../Form/Style.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';

const Form = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState({
    email: ""
  });

  const handler = (e) => {
    let fieldName, value;
    fieldName = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [fieldName]: value });

    // Email validation
    if (fieldName === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setErrors({ ...errors, email: "Invalid email address" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    }
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, message } = userData;

    if (name && email && message && !errors.email) {
      emailjs.send('service_hy2v79d', 'template_ceozgxf', {
        from_name: name,
        from_email: email,
        message: message,
        to_email: 'hello@sharifulpradhan.com',
      }, 'lx7uE8IUlLQTJlObS')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setUserData({
          name: "",
          email: "",
          message: "",
        });
        toast("I got your message 👍");
      }, (err) => {
        console.log('FAILED...', err);
        toast("Failed to send message");
      });
    } else {
      toast("Please fill all the details correctly");
    }
  };

  return (
    <form action="">
      <ToastContainer theme="dark" autoClose={2000}/>
      <input
        value={userData.name}
        type="text"
        name="name"
        id="name"
        placeholder="Your name..."
        onChange={handler}
      />
      <input
        value={userData.email}
        type="email"
        name="email"
        id="email"
        placeholder="Your email..."
        onChange={handler}
      />
      {errors.email && <p className="error">{errors.email}</p>}
      <textarea
        value={userData.message}
        name="message"
        id="message"
        cols="30"
        rows="10"
        placeholder="Your message..."
        onChange={handler}
      ></textarea>
      <button type="submit" onClick={submitData}>
        Submit
      </button>
    </form>
  );
};

export default Form;
