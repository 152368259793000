import React from "react";
import "../About/Style.scss";
import { motion } from "framer-motion";
import Heading from "../../Components/Heading/Heading";
import { animations } from "../../Styles/Animations/Animations";

import {
  BiLogoJavascript,
  BiLogoMongodb,
  BiLogoNodejs,
  BiLogoReact,
  BiLogoGraphql,
  BiLogoTypescript,
  BiLogoSass,
} from "react-icons/bi";
import { TbBrandNextjs } from "react-icons/tb";
import { SiExpress, SiTailwindcss } from "react-icons/si";

const About = () => {
  return (
    <>
      <Heading Heading={"About me"} />
      <div className="about">
        <div className="info">
          <motion.p {...animations.fade}>
            👋 I am Shariful Pradhan, a dedicated and enthusiastic Frontend Developer based in Dhaka, Bangladesh, with over 2 years of experience and a deep passion for JavaScript and innovative technology. 🌟</motion.p>

          <motion.p {...animations.fade}> I have been working in a remote setup for over 2 years. 🏡 I am a very well-managed remote worker. I have led the front-end development for various projects, including a Royalty Rewards platform 🎁 and a reusable website template for a web3 token platform. 🌐</motion.p>

          <motion.p {...animations.fade}>My proficiency spans multiple languages, libraries, and tools, such as ReactJS ⚛️, Redux 🌀, and Firebase 🔥, showcasing my versatility in the tech landscape. Additionally, I take pride in mentoring interns, fostering a collaborative learning environment that supports their growth and reinforces my own expertise and leadership capabilities. 🌱👨‍🏫
          </motion.p>
        </div>
        <motion.div {...animations.fade} className="skills">
          <h2>Stuff i know : </h2>
          <div className="icons">
            <BiLogoJavascript style={{ color: "yellow" }} />
            <BiLogoReact style={{ color: "deepskyblue" }} />
            <BiLogoSass style={{ color: "#cc6699" }} />
            <TbBrandNextjs style={{ color: "cornsilk" }} />
            <SiTailwindcss style={{ color: "skyblue" }} />
            <BiLogoNodejs style={{ color: "greenyellow" }} />
            <SiExpress style={{ color: "yellow" }} />
            <BiLogoMongodb style={{ color: "green" }} />
            <BiLogoTypescript style={{ color: "#007acc" }} />
            <BiLogoGraphql style={{ color: "#e535ab " }} />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default About;
